<template>
	<el-card class="customer-portrayal">
		<div class="tip">客户画像页面，展示客户的基础信息、锁粉信息等</div>
		<div class="set-tools">
			配置聊天工具栏
			<el-link type="primary" style="margin-left: 20px;" href="https://jusnn6k8al.feishu.cn/docs/doccnEKykHCzorfbdMfTkx8yXXz" target="_blank">如何添加到聊天工具栏？</el-link>
		</div>
		<div class="tools1" v-if="!isBuild">
			客户画像：
			<el-button type="primary" style="margin-left: 10px;" size="mini" @click="handleToBuild">创建自建应用</el-button>
		</div>
		<div class="tools1" v-else>
			客户画像：
			<span style="color: #666;">{{url}}</span>
			<el-button style="margin-left: 20px;" type="text" v-clipboard:copy="url" v-clipboard:success="onCopy" v-clipboard:error="onError">
				点击复制地址
			</el-button>
		</div>
		<div class="tools2">
			<div class="tit1">如何使用</div>
			<div class="tit2">登录企业微信，在与客户的聊天对话框右侧，点击侧边栏的图标，点击客户画像后即可使用</div>
			
			<el-link type="primary" href="https://jusnn6k8al.feishu.cn/docs/doccnRi8NQ41FqfNivzPvUDpDxb" target="_blank" style="margin: 10px 0;">
				为什么聊天工具栏添加成功后，在企业微信端看不到客户画像的信息？
			</el-link>
			
			<img class="tools2-img" src="https://cdn.dkycn.cn/melyshop/image/20210802-1335c93a-b74b-4fbf-88e9-a3c12c46abfa.png"></img>
		</div>
	</el-card>
</template>

<script>
	
	import {
		qyweixinsidebarconfig
	} from '@/api/sv1.0.0.js';
	
	export default {
		components: {},
		data() {
			return {
				isBuild: false,
				url: ''
			}
		},
		created() {
			this.mallkey = window.localStorage.getItem('mlmzMallKey')
			if (process.env.NODE_ENV == 'production'){
				this.url = 'https://wapapi.qidian.shop/tools/#/userInfo?isqy=1&mallkey='+this.mallkey
			}else{
				this.url = 'https://wap.dkyapitest.cn/tools/#/userInfo?isqy=1&mallkey='+this.mallkey
			}
		},
		beforeMount() {},
		mounted() {
			this.getData();
		},
		methods: {
			async getData(){
				try{
					const res = await qyweixinsidebarconfig();
					if (res.Result.IsFinishConfig){
						this.isBuild = true;
					}else{
						this.isBuild = false;
					}
				}catch(e){
					//TODO handle the exception
				}
			},
			handleToBuild(){
				this.$router.push({
					name: 'createApp'
				})
			},
			onCopy() {
				this.$message({
					message: "复制成功！",
					type: 'success'
				});
			
			},
			onError() {
				this.$message({
					message: "复制失败！",
					type: 'error'
				});
			},
		}
	}
</script>

<style lang="less" scoped>
	.customer-portrayal{
		.tip{
			font-size: 14px;
			color: #999;
			padding-bottom: 15px;
			border-bottom: 1px solid #eee;
		}
			
		.set-tools{
			margin-top: 30px;
			
			display: flex;
			align-items: center;
			font-size: 16px;
		}
		.tools1{
			font-size: 14px;
			display: flex;
			align-items: center;
			margin-top: 30px;
		}
		.tools2{
			font-size: 14px;
			margin-top: 30px;
			
			.tit1{
				font-size: 15px;
				margin-bottom: 10px;
			}
			.tit2{
				font-size: 13px;
				color: #666;
			}
			.tools2-img{
				display: block;
				max-width: 700px;
				margin-top: 10px;
			}
		}
	}
</style>
